import React from 'react'
import AppearanceSlide from './AppearanceSlide'
import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'

export default [
  <Slide bgColor="primary" key='intro-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      React and Friends
          </Heading>
    <Text margin="10px 0 0" textColor="tertiary" size={1} fit bold>
      An overview of our latest frontend stack
    </Text>
  </Slide>,
  <Slide bgColor="tertiary"  key='intro-2'>
    <Heading size={3} textColor="primary" margin="0 0 40px" caps>
      About Me
    </Heading>
    <Heading size={5} textColor="secondary">
      Nathan Kessler
    </Heading>
    <Heading size={5} textColor="secondary">
      GitHub: kesslern
    </Heading>
  </Slide>,
  <Slide bgColor="tertiary" key='intro-3'>
    <Heading size={3} textColor="primary" caps>
      About You
    </Heading>
  </Slide>,
  <AppearanceSlide
    key='intro-4'
    header="You know Javascript"
    bullets={[
      'Classes',
      'Spread Operator',
      'Destructuring',
      'Arrow Functions',
    ]}
  />,
  <AppearanceSlide
    key='intro-5'
    header="You know React"
    bullets={[
      'Class-based components',
      'Higher-order components',
      'Component libraries',
      'One of many style solutions',
    ]}
  />,
  <AppearanceSlide
    key='intro-6'
    header="You know Redux"
    bullets={[
      'Global state object',
      'Reducers are pure functions',
      'Actions are dispatched to reducers',
    ]}
  />,
  <AppearanceSlide
    key='intro-7'
    header="What we're covering today"
    bullets={[
      'Adding a React UI to a Java application',
      'Using hooks instead of class-based components',      
      'Simplifying Redux with Redux Starter Kit',
      'Client-side routing with React Router',
    ]}
  />,
]
