import React from 'react'

import {
  Appear,
  Heading,
  ListItem,
  List,
  Slide,
} from 'spectacle';


export default class AppearanceSlide extends React.Component {
  render() {
    const { header, bullets, ...otherProps} = this.props

    return (
      <Slide bgColor="primary" textColor="tertiary" align='flex-start center' {...otherProps}>
          <Heading size={6} textColor="secondary" caps textAlign='left'>
            {header}
          </Heading>
          <List>
            {bullets.map((it, index) => (
              <Appear key={index}>
                <ListItem style={{fontSize: '2.4rem'}}>{it}</ListItem>
              </Appear>
            ))}
          </List>
        </Slide>
    )
  }
}
