import React from 'react'
import Prism from 'prismjs'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'

import AppearanceSlide from './AppearanceSlide'
import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'

export default [
  <AppearanceSlide
    key='hooks-1'
    header="React Hooks"
    bullets={[
      'Use them!',
      'Share and compose stateful logic easier',
      'No more annoying higher-order components',
      'Less typing, less boilerplate'
  ]}/>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='hooks-2' textSize='1.5rem'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Retrieving data on component mount (class lifecycle)
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
      {`class DataFetcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }
  
  componentDidMount() {
    fetch('/api/cats')
      .then(response => response.json())
      .then(data => this.setState({ data }))
  }

  render() {
    return (
      this.state.data.map(cat => 
        <div>Breed: {cat.breed}</div>
      )
    )
  }
}`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start'  textSize='1.5rem' key='hooks-3'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Now, with hooks!
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
      {`function DataFetcher () {
  const [data, setData] = useState([])

  useEffect(() => {
    fetch('/api/cats')
      .then(response => response.json())
      .then(data => setData(data))
  })

  return (
    data.map(cat => 
      <div>Breed: {cat.breed}</div>
    )
  )
}`}</SyntaxHighlighter>
  </Slide>,
  <AppearanceSlide
    key='hooks-4'
    header="Learning more about hooks"
    bullets={[
      'Start with the official documentation',
      'Fun with React Hooks - Michael Jackson and Ryan Florence',
  ]}/>,
]
