import React from 'react'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import 'prismjs/themes/prism-dark.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import AppearanceSlide from './AppearanceSlide'
import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'

export default [
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Storing the data in Redux
    </Heading>
    <Appear>
      <Text margin="10px 0 0" textColor="tertiary" textSize='3rem' bold>
        Ow, the boilerplate
      </Text>
    </Appear>
  </Slide>,
  <AppearanceSlide
    key='redux-2'
    header="Let's add this data to Redux"
    bullets={[
      'Add a new reducer',
      'Add the reducer to root reducers',
      'Create an action creator',
      'Create a container for DataFetcher',
  ]}/>,
   <AppearanceSlide
    key='redux-3'
    header="An easier life with Redux Starter Kit"
    bullets={[
      'Sane defaults (redux-thunk, development middleware)',
      'Modify state in reducers',
      'Generate action creators for each reducer function',
      'Standardize usage patterns',
  ]}/>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='redux-4'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Creating a store with Redux Starter Kit
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`const store = configureStore({
  reducer: combineReducers({
    data: dataReducer
  })
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root'))`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='redux-5'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Creating a data slice
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`const dataSlice = createSlice({
  slice: 'data',
  initialState: [],
  reducers: {
    set: (state, action) => {
      return action.payload
    }
  }
})

const { reducer as dataReducer, actions } = dataSlice
const { set: setData } = actions
export { dataReducer, setData }`}</SyntaxHighlighter>
  </Slide>,
    <Slide bgColor="primary" key='intro-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Connecting the DataLoader to Redux   
    </Heading>
    <Appear>
      <Text margin="10px 0 0" textColor="tertiary" size={2} bold>
        No more containers!
      </Text>
    </Appear>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='redux-6'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      React-Redux hooks in DataLoader 
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`function DataFetcher () {
  const dispatch = useDispatch()
  const data = useSelector(state => state.data)

  useEffect(() => {
    fetch('/api/cats')
      .then(response => response.json())
      .then(data => dispatch(setData(data)))
  })

  return (
    data.map(cat => 
      <div>Breed: {cat.breed}</div>
    )
  )
}`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='redux-7'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Separating data fetching from display
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`function DataFetcher () {
  const dispatch = useDispatch()

  useEffect(() => {
    fetch('/api/cats')
      .then(response => response.json())
      .then(data => dispatch(setData(data)))
  })

  return null
}

function CatBreeds () {
  const data = useSelector(state => state.data)

  return (
    data.map(cat => 
      <div>Breed: {cat.breed}</div>
    )
  )
}`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='redux-8'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Data fetching with Redux-Thunk
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`const retrieveCatData = () => dispatch => {
    fetch('/api/cats')
      .then(response => response.json())
      .then(data => dispatch(setData(data)))
}

function DataFetcher () {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(retrieveCatData())
  })

  return null
}`}</SyntaxHighlighter>
  </Slide>,
]
