import React from 'react'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import 'prismjs/themes/prism-dark.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'
import AppearanceSlide from './AppearanceSlide'

export default [
  <Slide bgColor="primary" key='intro-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      And now, some other stuff
    </Heading>
  </Slide>,
   <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Material UI
    </Heading>
    <Text margin="10px 0 0" textColor="tertiary" textSize='3rem' bold>
      Most widely used Material implementation for React
    </Text>
  </Slide>,
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Don't eject!
    </Heading>
    <Text margin="10px 0 0" textColor="tertiary" textSize='3rem' bold>
      Customize CRA with Craco
    </Text>
  </Slide>,
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Validate your Redux state against a schema
    </Heading>
    <Text margin="10px 0 0" textColor="tertiary" textSize='2rem' bold>
      validate-prop-types is in OCLC's Artifactory
    </Text>
  </Slide>,
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Check out i18next
    </Heading>
    <Text margin="10px 0 0" textColor="tertiary" textSize='3rem' bold>
      Next-generation internationalization
    </Text>
  </Slide>,
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Develop components with React Storybook
    </Heading>
  </Slide>,
  <Slide bgColor="primary" key='redux-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      This is all on confluence!
    </Heading>
  </Slide>
] 
