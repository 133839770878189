import React from 'react'
import AppearanceSlide from './AppearanceSlide'
import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'

export default [
  <Slide bgColor="secondary" textColor="primary" key='gradle-1'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Bootstrap a new UI with Create React App
    </Heading>
    <Code textColor='tertiary'>
      npx create-react-app your-project-frontend
    </Code>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='gradle-2'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Creating a Gradle module
    </Heading>
    <div style={{ position: 'relative', height: '100%', width: '100%', flexGrow: '1' }}>
      <Appear>
        <div style={{ position: 'absolute', display: 'flex', backgroundColor: 'rgba(75, 0, 130)', height: '150px', width: '150px', margin: '23% 0 0 30%', zIndex: '10', textSize: '2em' }}>
          <div style={{ margin: 'auto', fontSize: '1.5rem' }}>CRA Production Build</div>
        </div>
      </Appear>
      <Appear>
        <div style={{ position: 'absolute', display: 'flex', backgroundColor: 'MediumBlue', height: '250px', width: '275px', margin: '15% 0 0 20%', zIndex: '9', textSize: '2em' }}>
          <div style={{ marginTop: '1em', marginLeft: '1em', fontSize: '1.5rem' }}>JAR file</div>
        </div>
      </Appear>
      <Appear>
        <div style={{ position: 'absolute', display: 'flex', backgroundColor: 'DarkSlateBlue', height: '350px', width: '475px', margin: '8% 0 0 15%', zIndex: '8', textSize: '2em' }}>
          <div style={{ marginTop: '.5em', marginLeft: '5em', fontSize: '1.5rem' }}>Java Application Classpath</div>
        </div>
      </Appear>
    </div>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" key='gradle-3'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Add it to the classpath
    </Heading>
    <Code textColor='tertiary'>
      runtime(project(':cats-frontend'))
    </Code>
  </Slide>,
  <AppearanceSlide
    key='gradle-4'
    header="Routing in the backend"
    bullets={[
      'Base path for API',
      'Base path for frontend -- serves index.html',
    ]}
  />
]
