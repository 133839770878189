import React from 'react'
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import 'prismjs/themes/prism-dark.css'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'

import {
  Appear,
  BlockQuote,
  Cite,
  Deck,
  Heading,
  ListItem,
  List,
  Quote,
  Slide,
  Text,
  CodePane,
  Code,
} from 'spectacle'

export default [
  <Slide bgColor="primary" key='intro-1'>
    <Heading size={1} fit caps lineHeight={1} textColor="secondary">
      Client-side routing
          </Heading>
    <Text margin="10px 0 0" textColor="tertiary" size={1} fit bold>
      Rewriting the current browser URL without a page refresh
    </Text>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='routing-1'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Add the Router provider 
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>, 
document.getElementById('root'))`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='routing-2'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Add some links
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`function CatBreeds () {
  const data = useSelector(state => state.data)

  return (
    data.map(cat => 
      <Link to={\`/\${cat.breed}\`}>{cat.breed}</Link>
    )
  )
}`}</SyntaxHighlighter>
  </Slide>,
  <Slide bgColor="secondary" textColor="primary" align='flex-start flex-start' key='routing-3' textSize='1.5rem'>
    <Heading size={5} textColor='primary' margin='10px 10px 40px'>
      Add the routes
    </Heading>
    <SyntaxHighlighter language="javascript" style={dark}>
{`function App() {
  const data = useSelector(state => state.data)

  return (
    <div className="App">
      <header className="App-header">
        <DataFetcher />
        <CatBreeds />
      </header>
      <section className="App-body">
        {data.map(cat =>
          <Route 
            key={cat.breed} 
            path={\`/\${cat.breed}\`} 
            render={() =>
              <img src={cat.image} />
            }
          />
        )}
      </section>
    </div>
  )
}`}</SyntaxHighlighter>
  </Slide>,
] 
