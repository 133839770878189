// Import React
import React from 'react'
import 'prismjs'
import 'prismjs/components/prism-groovy'
import 'prismjs/components/prism-jsx'

// Import Spectacle Core tags
import { Deck } from 'spectacle'

import createTheme from 'spectacle/lib/themes/default'

import IntroSlides from './1_intro'
import GradleSlides from './2_gradle'
import HooksSlides from './3_hooks'
import ReduxSlides from './4_redux'
import RoutingSlides from './5_routing'
import LastSlides from './6_speedround'

// Require CSS
require('normalize.css');

const theme = createTheme(
  {
    primary: 'white',
    secondary: '#1F2022',
    tertiary: '#03A9FC',
    quaternary: '#CECECE',
  },
  {
    primary: 'Montserrat',
    secondary: 'Helvetica',
  }
);

export default class Presentation extends React.Component {
  render() {
    return (
      <Deck
        transition={['slide']}
        transitionDuration={500}
        contentWidth={1200}
        contentHeight={800}
        theme={theme}
      >
        {IntroSlides}
        {GradleSlides}
        {HooksSlides}
        {ReduxSlides}
        {RoutingSlides}
        {LastSlides}
      </Deck>
    );
  }
}
